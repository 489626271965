.About-us{
    width: 100%;
    height: 100%;
    margin: 110px 0px;
    position: relative;
}

.about-continer{
    width: 100%;
    margin: auto;
    height: 100%;
}

.about-continer h2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    font-size: 24px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    color: #ccd6f6;
}

.about-continer h2 span{
    color: #64ffda;
    margin-right: 12px;
}

.about-continer h2::before{
    content: '';
    position: absolute;
    left: 0;
    width: 40%;
    height: 1px;
    background: #233554;
    margin-left: 200px;
}

.about-wrapper{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 40px;
}

.about-wrapper .img-content{
    position: relative;
    width: 320px;
    height: 350px;
    position: relative;
    z-index: 0;
    border-radius: 9px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 20px;
}

.about-wrapper .img-content img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    border-radius: 9px;
}

.about-wrapper .text-content{
    position: relative;
    font-family: 'Calibre','Inter','San Francisco','SF Pro Text',-apple-system,system-ui,sans-serif;
    color: #8892b0;
}

.about-wrapper .text-content p{
    width: 100%;
    max-width: 750px;
    margin-top: 40px;
    line-height: 2rem;
    font-size: 18px;
    margin: 12px 0px;
    font-weight: 500;
}

.about-wrapper .text-content ul{
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
    grid-column-gap:1rem;
    grid-row-gap:1rem;
}

.about-continer ul li{
    display: flex;
    align-items: center;
    list-style: none;
    position: relative;
    gap: 2rem;
    text-align: center;
    width: 100%;
    padding-left: 20px;
}

.about-wrapper ul li::after{
    content: "▹";
    position: absolute;
    left: 0px;
    color: #64ffda;
    font-size: 13px;
    line-height: 12px;
    box-sizing: inherit;
}

.about-wrapper .img-content::before{
    position: absolute;
    content: '';
    background: none;
    border: 2px solid #64ffda;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 9px;
    transform: translate(20px , 20px);
    transition: all 0.2s ease-in-out;
}

.about-wrapper .img-content .shadow{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #0887698e;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 8px;
    mix-blend-mode: screen;
}

.about-wrapper .img-content:hover .shadow{
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.about-wrapper .img-content:hover::before{
    transform: translate(15px , 15px);
    transition: all 0.2s ease-in-out;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .about-wrapper{
        flex-direction: column;
    }
}

@media screen and (min-width:200px) and (max-width:600px){
    .About-us{
        margin-top: -20px;
    }

    .about-continer{
        width: 100%;
    }
    .about-continer h2::before{
        content: '';
        position: absolute;
        left: 0;
        width: 40%;
        height: 1px;
        background: #233554;
        margin-left: 200px;
    }

    .about-wrapper .main-cont{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .about-wrapper .text-content ul{
        grid-template-columns: repeat(auto-fit , minmax(150px , 1fr));
       
    }

    .about-wrapper .img-content{
        width: 280px;
        height: 300px;
    }
    
    .about-wrapper .text-content p{
        font-size: 15px;
        font-weight: 550;
    }
}


  
  
 
