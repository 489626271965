main{
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 80vh;
    margin-top: 100px;
}

main .content{
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
} 

.content .hello{
    color: #64ffda;
    margin-bottom: 12px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
}


.content .name{
    color: #ccd6f6;
    
}

.content .role{
    color: #a8b2d1;
    animation: sclrolling 5s linear infinite;
    transform: translateX(100%);
}

.role-2{
    right: -100%;
    color: #64ffda;
    transform: translateX(200%);
}

.content h2{
    font-family: 'Calibre','Inter','San Francisco','SF Pro Text',-apple-system,system-ui,sans-serif;
    font-size: 4.8rem;
}

.content .descrip{
    width: 100%;
    max-width: 600px;
    margin-top: 40px;
    line-height: 2rem;
    color: #8892b0;
    font-size: 20px;
}

.content .descrip::selection , .content .descrip span::selection , .content h2::selection  , .content .hello::selection{
    background-color: #64ffda;
    color: #fff;
}

.content .descrip span{
    color: #64ffda;
}

.content button{
    background: none;
    outline: none;
    border-radius: 5px;
    color: #64ffda;
    border: 1px solid #64ffda;
    padding:15px 40px;
    margin-top: 50px;
    cursor: pointer;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    transition: all 0.2s ease-in;
}

.content button a{
    text-decoration:none;
    color: #64ffda;
}

.content button:hover{
    background: rgba(100,255,218,0.1);
    transition: all 0.2s ease-in;
}

@media screen and (min-width:600px) and (max-width:1000px){
    .content h2{
        font-size: 3rem;
    }

    main .content{
        top: 60%;
    } 

   
}

@media (max-width:600px){
    .content .name{
        font-size: 2.7rem;
    }

    main{
        margin-top: 100px;
    }

    .content .role{
        font-size: 2rem;
    }

    main .content{
        top: 48%;
    } 

    .content .descrip{
         font-size: 18px;
         line-height: 1.6rem;
         margin-top: 20px;
    }

    .content .descrip{
        margin-top:20px;
    }

    .content button{
        margin-top: 30px;
    }
}

@media (max-width:420px){
    .content h2{
        font-size: 2.2rem;
    }

    .role{
        font-size: 12px;
        color: #64ffda;
    }
}

.loder{
    position: relative;
    overflow: hidden;
    width: 1000px;
    height: 100%;
}

