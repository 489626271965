/* html , body{
    overflow-y: hidden;
} */

.preloader{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #020c1b;
    box-sizing: border-box;
    opacity: 1;
    animation: opacitydiv 1s ease-in-out forwards 4s;
}

.preloader h1{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
} 

.logo-svg{
    animation: fill 2s ease-in-out forwards 3s;
    transform: scale(1);
    opacity: 1;
    margin: 0% 4%;
}

.logo-svg path:nth-child(2){
    stroke-dasharray: 484;
    stroke-dashoffset: 483;
    animation:  anim 3s ease forwards;
}

.logo-svg path:nth-child(3){
    stroke-dasharray: 754;
    stroke-dashoffset: 754;
    animation:  anim 3s ease forwards ;
}
.logo-svg path:nth-child(4){
    stroke-dasharray: 490;
    stroke-dashoffset: 490;
    animation:  anim 3s ease forwards ;
}
.logo-svg path:nth-child(5){
    stroke-dasharray: 226;
    stroke-dashoffset: 226;
    animation:  anim 3s ease forwards;
}
.logo-svg path:nth-child(6){
    stroke-dasharray: 549;
    stroke-dashoffset: 549;
    animation:  anim 3s ease forwards ;
}
.logo-svg path:nth-child(7){
    stroke-dasharray: 297;
    stroke-dashoffset: 297;
    animation:  anim 3s ease forwards ;
}
.logo-svg path:nth-child(8){
    stroke-dasharray: 490;
    stroke-dashoffset: 490;
    animation:  anim 3s ease forwards;
}
.logo-svg path:nth-child(9){
    stroke-dasharray: 385;
    stroke-dashoffset: 385;
    animation:  anim 3s ease forwards ;
}
.logo-svg path:nth-child(10){
    stroke-dasharray: 226;
    stroke-dashoffset: 226;
    animation:  anim 3s ease forwards ;
}
.logo-svg path:nth-child(11){
    stroke-dasharray: 549;
    stroke-dashoffset: 549;
    animation:  anim 3s ease forwards ;
}

@keyframes anim{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fill{
    to{
        transform: scale(3);
        opacity: 0;
    }
}


@keyframes opacitydiv{
    to{
        opacity: 0;
    }
}