footer ul{
    display: none;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

footer p{
    text-align: center;
    margin: 202px 0 22px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    color: #a8b2d1;
    transition: all 0.2s linear;
    cursor: pointer;
}

footer ul li{
    margin: 0px 20px;
    list-style: none;
}

footer ul li svg{
    font-size: 1.2rem;
    color: #8892b0;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

footer ul li svg:hover{
    color: #64ffda;
    transition: all 0.2s linear;
}

footer p:hover{
    color: #64ffda;
    transition: all 0.2s linear;
}


@media (max-width:600px){
    footer ul{
        display: flex;
        margin-top: 120px;
    }

    footer p{
        margin-top: 42px;
        margin-bottom: 32px;
    }
}