
nav{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
}

.nav-icon{
    display: none;
    flex-direction: column;
    width: 30px;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transform-origin: center center;
    z-index: 500;
}

.active-nav-icon{
    position: relative;
    display: none;
    flex-direction: column;
    width: 30px;
    height: 100%;
    cursor: pointer;
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
    transform-origin: center center;
    z-index: 500;
}

.nav-icon  div , .active-nav-icon div{
    height: 2.4px;
    background: #64ffda;
    margin-top: 8px;
    border-radius: 4px;
    text-align: left;
    display: block;
}

.active-nav-icon div{
    width: 100%;
}

.nav-icon  div:first-child {
    width: 100%;
}
.nav-icon div:nth-child(2) {
    width: 90%;
    transition: all 0.3s ease-in-out;
}
.nav-icon div:nth-child(3) {
    width: 70%;
    transition: all 0.3s ease-in-out;
}

.active-nav-icon div:nth-child(2){
    position: relative;
    transform: rotate(60deg) translateX(-2px) translateY(8px);
    transition: all 0.3s ease-in-out;
}

.active-nav-icon div:nth-child(3){
    position: relative;
    transform: rotate(-60deg) translateX(10px) translateY(2px);
    transition: all 0.3s ease-in-out;
}



@media (max-width:1000px){
    .nav-icon{
        display: flex;
    }
    .active-nav-icon{
        display: flex;
    }
    .Nav-continer ul{
        display: none;
    }
}
@media (min-width:1000px){
    .Nav-continer ul{
        display: flex;
    }
}

nav h3{
    color: #64ffda;
}


.Nav-continer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 86%;
    margin: auto;
    padding: 32px 0px;
}

.Nav-continer img{
    width: 100px ;
    height: 100px;
}

.Nav-continer ul{
    align-items: center;
}

.Nav-continer ul li:not(.last-child){
    margin-right: 72px;
}

.Nav-continer ul li{
    list-style: none;
    color: #a8b2d1;
    cursor: pointer;
    transition: all 0.2s ease-in;
    font-size: 14px;
}

.Nav-continer ul li span{
    margin-right:5px;
    color: #64ffda;
}

.Nav-continer ul .last-child{
    border-radius: 5px;
    color: #64ffda;
    border: 1px solid #64ffda;
    padding:12px 32px;
}

.Nav-continer ul li:hover{
    color: #64ffda;
    transition: all 0.2s ease-in;
}

.Nav-continer ul li a{
    text-decoration: none;
    color: #a8b2d1;
}

@media (max-width:1000px){
    .Nav-continer img{
        width: 90px ;
        height: 90px;
    }
}

