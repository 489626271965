.not-worthy{
    width: 100%;
    max-width: 100%;
    margin-top: 120px;
    height: 100%;
}

.not-worthy .head{
    width: 100%;
    text-align: center;
}

.not-worthy .head h2{
    position: relative;
    width: 100%;
    font-size: 24px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    color: #ccd6f6;
}

.not-worthy .head p{
    color: #64ffda;
    margin-bottom: 12px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    margin-top: 12px;
}

.not-worthy .net-content{
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(350px , 1fr));
    grid-column-gap:2rem;
    grid-row-gap:2rem;
    margin-top: 50px;
}

.not-worthy .net-content .each-net{
    height: 100%;
    width: 100%;
    background: #112240;
    box-shadow: 0 10px 30px -15px rgba(2,12,27,0.7);
    padding: 32px 30px;
    border-radius: 4px;
    transition: all 0.1s linear;
}

.not-worthy .net-content .each-net:hover{
    transform: translateY(-10px);
    transition: all 0.1s linear;
}

.each-net .socila-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.each-net .socila-links li{
    list-style: none;
}

.each-net .socila-links li:first-child{
    color: #64ffda;
    font-size: 45px;
    font-weight: 200;
}

.each-net .socila-links li:last-child{
    color: #ccd6f6;
    font-size: 22px;
    cursor: pointer;
}

.each-net h3{
    font-size: 20px;
    font-family: 'Calibre','Inter','San Francisco','SF Pro Text',-apple-system,system-ui,sans-serif;
    color: #ccd6f6;
    margin-top: 8px;
    transition: all 0.5s ease;
}

.each-net h3:hover{
    color: #64ffda;
    transition: all 0.5s ease;
}

.each-net p{
    color: #ccd6f6;
    margin-top: 10px;
}

.each-links {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.each-links  li{
    list-style: none;
    color: #a8b2d1;
    font-size: 13px;
    font-weight: 500;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
}

.each-links  li{
    margin-right: 12px;
}

@media screen and (min-width:200px) and (max-width:370px){
    .not-worthy .net-content{
        grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
    }
}