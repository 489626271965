.sidebar{
   overflow-y: hidden;
}

.slider-1{
    top: 0;
    bottom: 0;
    background: #2d9687;
    position: fixed;
    width: 200%;
    height: 100%;
    z-index: 2;
    transition: all 0.4 ease-in-out;
}

.sidebar{
    position: relative;
    height: 100%;
    overflow-y:hidden;
}

.slider-2{
    top: 0;
    bottom: 0;
    background: #e9b171;
    position: fixed;
    width: 200%;
    height: 100%;
    transition: all 0.4 ease-in-out;
    z-index: 2;
}

.slider-3{
    top: 0;
    bottom: 0;
    background: #e2b8aa;
    position: fixed;
    width: 200%;
    height: 100%;
    transition: all 0.4 ease-in-out;
    z-index: 2;
}

.slider-4{
    top: 0;
    bottom: 0;
    background: #c22085;
    position: fixed;
    width: 200%;
    height: 100%;
    transition: all 0.4 ease-in-out;
    z-index: 2;
}
.slider-5{
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    background: #0a192f;
    position: fixed;
    width: 100%;
    height: 100%;
    transition: all 0.4 ease-in-out;
    z-index: 1;
}

.slider-5 .home-links{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    width: 100%;
}

.slider-5 .home-links li:not(.last-child){
    list-style: none;
    padding: 22px 0px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    color: #a8b2d1;
    text-align: left;
    font-size: 20px;
}

.slider-5 .home-links li span{
    color: #2d9687;
    margin-right: 12px;
}

.slider-5 .home-links .last-child{
    list-style: none;
    margin-top: 20px;
    border-radius: 5px;
    color: #64ffda;
    border: 1px solid #64ffda;
    padding:12px 32px;
    width: 120px;
    transition: all 0.5s ease-in-out;
}

.slider-5 .home-links li:not(.last-child):hover{
    color: #2d9687;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}


.fix-content-slider{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    right: 10%;
    height: 600px;
}

.fix-content-slider li{
    list-style: none;
    margin-top: 20px;
}

.fix-content-slider li svg{
    font-size: 1.5rem;
    color: #8892b0;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.fix-content-slider::before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 150px;
    background: #8892b0;
}

.fix-content-slider li svg:hover{
    color: #64ffda;
    transition: all 0.2s ease-in;
    transform: translateY(-5px);
}

.home-links li a{
    text-decoration:none;
    cursor: pointer;
    color: #a8b2d1;
}


.fix-content-slider li:last-child{
    cursor: pointer;
}