@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500&family=Fira+Mono:wght@400;500;700&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;1,200&display=swap');

:root{
  scrollbar-face-color: #64ffda; 
  scrollbar-track-color: #112240; 
  scrollbar-color: #64ffda;
  scrollbar-width: thin;
  scrollbar-shadow-color: #64ffda;
}
 
*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html , body {
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a192f;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main{
  margin: 0 15%;
}

@media (max-width:600px){
  .main{
    margin: 0 4%;
  }
  .curosr{
    display: none;
  }
  
}

.curosr{
  width: 40px;
  height: 40px;
  background:transparent;
  position: absolute;
  border-radius: 50%;
  transform: translate(-50% , -50%);
  transition: linear ;
  pointer-events: none;
  z-index: 100;
  border: 2px solid #e2b8aa;
  mix-blend-mode: difference;

}


::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background: #112240;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb{
  background: #64ffda;
  border-radius: 12px;
}


