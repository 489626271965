.fix-content-1{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    left: 5%;
    height: 600px;
}

.fix-content-1 li{
    list-style: none;
    margin-top: 20px;
}

.fix-content-1 li svg{
    font-size: 1.5rem;
    color: #8892b0;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.fix-content-1::before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 150px;
    background: #8892b0;
}

.fix-content-1 li svg:hover{
    color: #64ffda;
    transition: all 0.2s ease-in;
    transform: translateY(-5px);
}

.fix-content-2{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    right: 5%;
    height: 600px;
}

.fix-content-2 p{
    writing-mode: vertical-rl;
    color: #8892b0;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    transition: all 0.2s ease-in;
    cursor: pointer;
}
.fix-content-2 p a{
    color: #8892b0;
    text-decoration: none;
}

.fix-content-2::before{
    content: '';
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 150px;
    background: #8892b0;
}

.fix-content-2 p:hover{
    color: #64ffda;
    transition: all 0.2s ease-in;
    transform: translateY(-5px)
}

@media (max-width:600px){
    .fix-content-2 , .fix-content-1{
        display: none;
    }
}