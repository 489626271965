.syahello{
    margin-top: 100px;
    text-align: center;
    width: 100%;
    margin-bottom: 80px;
}

.syahello .tittle{
    color: #64ffda;
    margin-bottom: 12px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    margin: 10px 0px;
}

.syahello  h2{
    margin: 20px 0px;
    color: #ccd6f6;
    font-family: 'Calibre','Inter','San Francisco','SF Pro Text',-apple-system,system-ui,sans-serif;
    font-size: 3.6rem;
}

.syahello  .des{
   color: #8892b0;
   max-width: 550px;
   width: 100%;
   margin: 10px auto;
   line-height: 2rem;
   font-size: 20px;
}

.syahello button{
    color: #64ffda;
    border: 1px solid #64ffda;
    border-radius: 9px;
    padding: 15px 28px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    background: none;
    outline: none;
    margin: 20px 0px;
    cursor: pointer;
}


@media screen and (min-width:200px) and (max-width:750px){
    
   .syahello  h2{
    font-size: 2.6rem;
}

    .syahello  .des{
    width: 100%;
    margin: 10px auto;
    line-height: 1.6rem;
    font-size: 18px;
    }

    .syahello{
        margin-top:150px ;
        margin-bottom: 170px;
    }
}