.review{
    margin-top: 70px;
    margin-bottom: 100px;
    width: 100%;
    height: 100%;
}

.review .review-head{
    width: 100%;
    text-align: left;
}

.review .review-head h2{
    position: relative;
    width: 100%;
    font-size: 24px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    color: #ccd6f6;
}

.review .review-head p{
    color: #64ffda;
    margin-bottom: 12px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    margin-top: 12px;
}



.review  .Testimonial{
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    height: 100%;
    background: #112240;
    box-shadow: 0 10px 30px -15px rgba(2,12,27,0.7);
    border-radius: 9px;
    padding: 32px 30px;
    box-sizing: border-box;
}

.Testimonial .head-testi img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.Testimonial .head-testi{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Testimonial .head-testi h3{
    margin-left: 15px;
    position: relative;
    width: 100%;
    font-size: 18px;
    color: #ccd6f6;
}

.Testimonial .testi-cont p{
    width: 100%;
    line-height: 25px;
    color: #ccd6f6;
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0px;
}

.Testimonial .testi-cont p span{
    color: #64ffda;
    text-decoration: underline;
}

.Testimonial .testi-cont svg{
    list-style: none;
    color: #a8b2d1;
    font-size: 27px;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s linear;
}

.Testimonial .testi-cont svg:hover{
     color: #64ffda;
     transition: all 0.3s linear;
}