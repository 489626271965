.contact-us{
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 50px;
}

.contact-us .head-con h2{
    position: relative;
    width: 100%;
    font-size: 24px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    color: #ccd6f6;
}

.contact-us .head-con p{
    color: #64ffda;
    margin-bottom: 12px;
    font-family: 'SF Mono','Fira Code','Fira Mono','Roboto Mono';
    margin-top: 12px;
}

.contact-us form{
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(550px , 1fr));
    grid-column-gap:2rem;
    grid-row-gap:2rem;
    margin-top: 50px;
}

.contact-us form .each-feilds{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.contact-us form .each-feilds label{
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translate(-15px , -50%);
    color: #ccd6f6;
    font-family: 'Calibre','Inter','San Francisco','SF Pro Text',-apple-system,system-ui,sans-serif;
    font-size: 19px;
    margin-bottom: 12px;
    font-size: 14px;
    pointer-events: none;
    padding: 5px 12px;
    transition: all 0.1s linear;
}

.contact-us form .each-feilds input{
    background: transparent;
    padding: 15px 12px;
    color: #fff;
    font-size: 18px;
    border: none;
    outline: none;
}

.contact-us form .each-feilds input:focus + label{
   top: 5%;
   left: 10%;
   transform: translate(-2% , -55%);
   background: #0a192f;
   font-size: 16px;
   transition: all 0.1s linear;
}



.each-feilds{
    position: relative;
    border: 1px solid #64ffda;
    border-radius: 22px;
}

.contact-us button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 22px;
    cursor: pointer;
    border: 1px solid #64ffda;
    background: rgba(100,255,218,0.1);
    outline: none;
    color: #fff;
    margin-top: 30px;
    border-radius: 22px;
    transition: all 0.1s linear;
}


.contact-us button svg{
    font-size: 20px;
    margin: 0px 8px;
    transition: all 0.1s linear;
}

.contact-us button:hover svg{
    transform: translateX(10px);
    transition: all 0.1s linear;
}

.contact-us button:hover{
    background: transparent;
}

@media screen and (min-width:200px) and (max-width:550px){
    .contact-us form{
        grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
    }
}